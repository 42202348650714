import { Injectable } from "@angular/core";

export class ToastStatus {
    public static SUCCESS = 'success';
    public static DANGER = 'danger';
    public static WARNING = 'warning';
    public static PRIMARY = 'primary';
    public static DEFAULT = 'default';
    public static INFO = 'info';
};

/**
 * Service to show tostr message to the user
 */
@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private toastrService: any;
/**
 * Constructor
 * @param toastrService 
 */
constructor() {
  }

  /**
   * To show toast to the User
   * @param status status of the toast
   * @param title title of the toaster
   * @param message body of the toaster
   */
  showToast(status, title ,message) {
    this.toastrService.show(message,title, {status});
  }

  /**
   * To show the sucess toast to the user
   * @param message 
   * @param title 
   */
  showSuccessToast(message, title="Success"){
    this.showToast(ToastStatus.SUCCESS, title,message);
  }

  /**
   * To sho the error toast to the user
   * @param message 
   * @param title 
   */
  showErrorToast(message, title="Error"){
    this.showToast(ToastStatus.DANGER,title,message);
  }
}