import { Injectable } from "@angular/core";

/**
 * Service to show tostr message to the user
 */
@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    constructor() {

    }

    silly(...args) {

    }

    log(...args) {

    }

    info(...args) {

    }

    error(...args) {

    }

}